import { createRequestUrl } from '../../../../play-core/utils/request-url-helper';
export const PacPageIdApiPath = '/page';
export const loadPacPage = async (axios, pageId, preview, previewDate) => {
  const requestUrl = createRequestUrl(PacPageIdApiPath, {
    pageId,
    preview,
    previewDate
  });
  const {
    data
  } = await axios.get(requestUrl);
  return data.data;
};